"use client";

import { useCallback } from "react";
import { useRouter } from "next/navigation";

import { SignInForm } from "@/components/form/sign-in";
import { type SearchParams } from "@/types";
import { getNewPath, getParam } from "@/utils/misc";

export const Login = ({ searchParams }: { searchParams?: SearchParams }) => {
  const router = useRouter();

  const onSignInSuccess = useCallback(() => {
    const next = searchParams && getParam(searchParams, "next");
    const type = searchParams && getParam(searchParams, "type");
    if (next) {
      router.push(
        getNewPath({ destination: "/", searchParams: { next, type } }),
      );
      return;
    } else
      router.push(
        getNewPath({ destination: "/", searchParams: { next, type } }),
      );
  }, [router, searchParams]);

  return <SignInForm onSignInSuccess={onSignInSuccess} />;
};
